import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {Avatar} from "@/shared/Avatar.jsx";
import {GAME_BOX_ICONS} from "@/app/constants.mjs";
import {GAME_SYMBOL_LOL} from "@/game-lol/definition.mjs";
export const LoLIconInner = GAME_BOX_ICONS[GAME_SYMBOL_LOL];
export const LoLIcon = ({width}) => {
  const _components = {
    div: "div",
    ..._provideComponents()
  };
  return _jsx(_components.div, {
    style: {
      width,
      height: width,
      color: "black",
      background: "#D5A038",
      borderRadius: 8
    },
    children: _jsx(LoLIconInner, {
      width: width,
      height: width
    })
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h3: "h3",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {SquareAvatar, StarIcon} = _components;
  if (!SquareAvatar) _missingMdxReference("SquareAvatar", true);
  if (!StarIcon) _missingMdxReference("StarIcon", true);
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Should always try to include the ", _jsx(_components.code, {
        children: "href"
      }), ", ", _jsx(_components.code, {
        children: "linkDesc"
      }), ", and ", _jsx(_components.code, {
        children: "alt"
      }), " prop"]
    }), "\n", _jsx(_components.code, {
      preview: "flex column align-start gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `<Avatar
  avatarWidth={128}
  avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg"
  variant="square"
  dataMask="square"
  dataIcon={<LoLIcon />}
/>`,
      children: _jsx(Avatar, {
        avatarWidth: 128,
        avatar: "https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg",
        variant: "square",
        dataMask: "square",
        dataIcon: _jsx(LoLIcon, {})
      })
    }), "\n", _jsx(_components.h3, {
      id: "squareavatar",
      children: _jsx(_components.a, {
        href: "#squareavatar",
        children: "SquareAvatar"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column align-start gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `<SquareAvatar avatar={null} title="Character name" />
<SquareAvatar avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="Character name" />
<SquareAvatar avatar={<StarIcon />} title="Character name" />
<SquareAvatar roleIcon={<StarIcon />} avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="Character name" />`,
      children: [_jsx(SquareAvatar, {
        avatar: null,
        title: "Character name"
      }), _jsx(SquareAvatar, {
        avatar: "https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg",
        title: "Character name"
      }), _jsx(SquareAvatar, {
        avatar: _jsx(StarIcon, {}),
        title: "Character name"
      }), _jsx(SquareAvatar, {
        roleIcon: _jsx(StarIcon, {}),
        avatar: "https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg",
        title: "Character name"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type SquareAvatarProps = {\n  avatar: string | React.ReactNode;\n  title: string | React.ReactNode;\n  href?: string;\n  roleIcon?: React.ReactNode;\n  avatarWidth?: number;\n  className?: string;\n  linkDesc?: string;\n};\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
